<!-- Previous script section remains unchanged -->
<script setup>
import { ref, computed, watch } from 'vue'
import { StoryblokComponent } from '@storyblok/vue'

const props = defineProps({
    blok: {
        type: Object,
        default: () => ({}),
    },
});

const route = useRoute()
const { markets } = useAppConfig()
const { t } = useI18n()

// Initialize serviceType based on Storyblok config
const serviceType = ref(props.blok.showCategorySwitch ? 'move' : (props.blok.defaultCategory || 'move'))
const distanceRange = ref('0-3') // Only for move &amp; deliver
const stairsEnabled = ref(false) // Controls if stairs are being used
const selectedStairLevel = ref(null) // Stores the selected level
const hasHelper = ref(false)

// Watch stairs enabled state to set initial level
watch(stairsEnabled, (newVal) => {
  if (newVal) {
    selectedStairLevel.value = '1'
  } else {
    selectedStairLevel.value = null
  }
})

// Computed value for price calculations that combines stairs state
const stairLevels = computed(() => {
  return stairsEnabled.value ? selectedStairLevel.value : false
})

// Get image URL from Storyblok
const getImageUrl = (size, type) => {
  const imageBlock = props.blok.sizeImages.find(block => 
    type === 'move' ? block.component === 'movingImages' : block.component === 'recyclingImages'
  )
  
  const sizeMap = {
    'S': 'sImage',
    'M': 'mImage',
    'L': 'lImage',
    'XL': 'xlImage'
  }
  
  const imageField = sizeMap[size]
  return imageBlock[imageField].filename
}

// Get description from Storyblok
const getDescription = (size, type) => {
  const imageBlock = props.blok.sizeImages.find(block => 
    type === 'move' ? block.component === 'movingImages' : block.component === 'recyclingImages'
  )
  
  const sizeMap = {
    'S': 'sImageDescription',
    'M': 'mImageDescription',
    'L': 'lImageDescription',
    'XL': 'xlImageDescription'
  }
  
  const descriptionField = sizeMap[size]
  return imageBlock[descriptionField]
}

// Price ranges based on the tables
const moveDeliverPrices = {
  S: {
    '0-3': { min: 100, max: 300 },
    '3-20': { min: 150, max: 300 },
    '20-40': { min: 200, max: 300 },
    '40-70': { min: 400, max: 600 }
  },
  M: {
    '0-3': { min: 150, max: 400 },
    '3-20': { min: 200, max: 500 },
    '20-40': { min: 300, max: 600 },
    '40-70': { min: 500, max: 700 }
  },
  L: {
    '0-3': { min: 300, max: 600 },
    '3-20': { min: 400, max: 800 },
    '20-40': { min: 500, max: 1000 },
    '40-70': { min: 700, max: 1000 }
  },
  XL: {
    '0-3': { min: 500, max: 800 },
    '3-20': { min: 700, max: 1200 },
    '20-40': { min: 900, max: 1500 },
    '40-70': { min: 1100, max: 1500 }
  }
}

const removeRecyclePrices = {
  S: { min: 145, max: 250 },
  M: { min: 280, max: 450 },
  L: { min: 400, max: 650 },
  XL: { min: 750, max: 1200 }
}

// Currency conversion and symbol based on market
const currencyInfo = computed(() => {
  const market = route.query.market
  if (market === markets.uk) {
    return { symbol: '£', divider: 10 }
  } else if (market === markets.se) {
    return { symbol: 'kr', divider: 1 }
  } else if (market === markets.de || market === markets.pt) {
    return { symbol: '€', divider: 10 }
  }
  // Default to euro if no market selected
  return { symbol: '€', divider: 10 }
})

// Convert price based on market
const convertPrice = (price) => {
  if (currencyInfo.value.divider === 1) return price
  return Math.round(price / currencyInfo.value.divider)
}

const sizes = ['S', 'M', 'L', 'XL']

const distanceRanges = ['0-3', '3-20', '20-40', '40-70', '70+']
const stairLevelsOptions = [
  { value: '1', label: computed(() => t('priceEstimate.stairs.level', { count: 1 })) },
  { value: '2', label: computed(() => t('priceEstimate.stairs.level', { count: 2 })) },
  { value: '3', label: computed(() => t('priceEstimate.stairs.level', { count: 3 })) },
  { value: '4', label: computed(() => t('priceEstimate.stairs.level', { count: 4 })) },
  { value: '5', label: computed(() => t('priceEstimate.stairs.level', { count: 5 })) }
]

// Calculate stairs cost based on size and levels
const calculateStairsCost = (size, levels) => {
  const levelsNum = parseInt(levels)
  if (size === 'S' || size === 'M') {
    return 30
  } else if (size === 'L') {
    return levelsNum <= 2 ? 30 : 40 * (levelsNum - 2)
  } else { // XL
    return levelsNum <= 2 ? 50 : 70 * (levelsNum - 2)
  }
}

// Calculate price range for a specific size
const getPriceRange = (size) => {
  if (serviceType.value === 'move') {
    // Get base price for move & deliver
    const baseRange = distanceRange.value === '70+' ? '40-70' : distanceRange.value
    const basePrice = moveDeliverPrices[size][baseRange]
    let minPrice = basePrice.min
    let maxPrice = basePrice.max

    // Stairs impact
    if (stairLevels.value) {
      const stairsCost = calculateStairsCost(size, stairLevels.value)
      minPrice += stairsCost
      maxPrice += stairsCost
    }

    // Helper impact
    if (!hasHelper.value) {
      if (size === 'L') {
        minPrice += 40
        maxPrice += 40
      } else if (size === 'XL') {
        minPrice += 100
        maxPrice += 100
      }
    }

    // For long distance moves (70+ km), use the adjusted max price as the minimum
    if (distanceRange.value === '70+') {
      return {
        min: convertPrice(maxPrice),
        max: null,
        symbol: currencyInfo.value.symbol,
        isLongDistance: true
      }
    }

    return {
      min: convertPrice(minPrice),
      max: convertPrice(maxPrice),
      symbol: currencyInfo.value.symbol,
      isLongDistance: false
    }
  } else {
    // Remove & recycle pricing
    let minPrice = removeRecyclePrices[size].min
    let maxPrice = removeRecyclePrices[size].max

    // Stairs impact - using same logic as move & deliver
    if (stairLevels.value) {
      const stairsCost = calculateStairsCost(size, stairLevels.value)
      minPrice += stairsCost
      maxPrice += stairsCost
    }

    // Helper impact
    if (!hasHelper.value) {
      if (size === 'L' || size === 'XL') {
        minPrice += 50
        maxPrice += 50
      }
    }

    return {
      min: convertPrice(minPrice),
      max: convertPrice(maxPrice),
      symbol: currencyInfo.value.symbol,
      isLongDistance: false
    }
  }
}
</script>

<template>
  <section class="bg-white dark:bg-space-blue" :id="blok.id ? blok.id : null">
    <div class="py-8 px-4 mx-auto max-w-screen-xl lg:px-12 text-center items-center">
      <div class="max-w-screen-xl mx-auto">
        <h1 class="mb-6 font-light text-gray-900 dark:text-white">
          {{ props.blok.title }}
        </h1>
        <p class="mb-8 font-light text-gray-500 lg:mb-8 dark:text-gray-400 sm:text-xl">
            {{ props.blok.description }}
        </p>

        <div class="block sm:space-x-8 py-4 bg-gradient-to-r to-gray-50 from-gray-200 dark:from-midnight-blue dark:to-deep-blue rounded-3xl">
            <div v-if="props.blok.showCategorySwitch" class="block sm:flex items-center mb-2 justify-center p-4 sm:pt-0">
              <h2 class="text-2xl font-medium text-center dark:text-white mr-3">{{ props.blok.categorySwitchTitle }} </h2>
              <ul class="flex mt-3 sm:mt-0 text-sm font-medium text-center justify-center text-gray-500 dark:text-gray-400">
                <li class="focus-within:z-10">
                  <a 
                    href="#" 
                    @click.prevent="serviceType = 'move'"
                    :class="[
                      'inline-block p-2 sm:p-3 min-w-24 border-2 transition-all rounded-l-lg',
                      serviceType === 'move'
                        ? 'border-purple-berry bg-purple-berry-10 dark:bg-purple-berry/20 text-purple-berry dark:text-purple-berry-30'
                        : 'border-gray-300 dark:border-gray-700 hover:border-purple-berry-30 dark:hover:border-purple-berry'
                    ]"
                    :aria-current="serviceType === 'move' ? 'page' : undefined"
                    data-gtm="pricetool-moving"
                  >
                    {{ props.blok.movingTitle }}
                  </a>
                </li>
                <li class="focus-within:z-10">
                  <a 
                    href="#" 
                    @click.prevent="serviceType = 'remove'"
                    :class="[
                      'inline-block p-2 sm:p-3 min-w-24 border-2 transition-all rounded-r-lg',
                      serviceType === 'remove'
                        ? 'border-purple-berry bg-purple-berry-10 dark:bg-purple-berry/20 text-purple-berry dark:text-purple-berry-30'
                        : 'border-gray-300 dark:border-gray-700 dark:text-gray-500 hover:border-purple-berry-30 dark:hover:border-purple-berry'
                    ]"
                    data-gtm="pricetool-recycling"
                  >
                    {{ props.blok.recyclingTitle }}
                  </a>
                </li>
              </ul>
            </div>
            
            <div class="space-y-6 px-4 sm:px-6">
              <!-- Price Range Grid -->
              <div class="grid grid-cols-2 md:grid-cols-4 gap-4 md:gap-6">
                <div v-for="size in sizes" :key="size" class="p-4">
                  <div class="flex flex-col items-center">
                    <img 
                      :src="getImageUrl(size, serviceType)"
                      :alt="`Size ${size} illustration`"
                      class="h-24 w-24 sm:h-32 sm:w-32 object-contain"
                    />
                    <div class="text-center mb-1">
                      <p class="text-xs sm:text-sm text-gray-600 dark:text-gray-400 mt-1">
                        {{ getDescription(size, serviceType) }}
                      </p>
                    </div>
                    <div class="text-center">
                      <p class="text-2xl sm:text-3xl font-semibold text-minty-green font-serif tracking-normal">
                        <template v-if="getPriceRange(size).isLongDistance">
                          <template v-if="getPriceRange(size).symbol === 'kr'">
                            {{ getPriceRange(size).min }}+ {{ getPriceRange(size).symbol }}
                          </template>
                          <template v-else>
                            {{ getPriceRange(size).symbol }}{{ getPriceRange(size).min }}+
                          </template>
                        </template>
                        <template v-else>
                          <template v-if="getPriceRange(size).symbol === 'kr'">
                            {{ getPriceRange(size).min }}-{{ getPriceRange(size).max }} {{ getPriceRange(size).symbol }}
                          </template>
                          <template v-else>
                            {{ getPriceRange(size).symbol }}{{ getPriceRange(size).min }}-{{ getPriceRange(size).max }}
                          </template>
                        </template>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              
              <!-- Long Distance Warning -->
              <div v-if="distanceRange === '70+' && serviceType === 'move'" class="bg-yellow-50 dark:bg-yellow-900/20 border border-yellow-200 dark:border-yellow-700 rounded-lg p-4 mt-4">
                <p class="text-sm text-yellow-800 dark:text-yellow-200">
                  {{ props.blok.longDistanceWarning }}
                </p>
              </div>

              <!-- Distance Range (Move & Deliver only) -->
              <div v-if="serviceType === 'move'" class="mt-8">
                <label class="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2 text-left">
                  {{ props.blok.distanceDescription }}
                </label>
                <div class="flex text-sm flex-wrap gap-3">
                  <button
                    v-for="range in distanceRanges"
                    :key="range"
                    @click="distanceRange = range"
                    :class="[
                      'px-2 py-2 sm:py-3 rounded-lg border-2 sm:text-base transition-colors',
                      distanceRange === range
                        ? 'border-purple-berry bg-purple-berry-10 dark:bg-purple-berry/20 text-purple-berry dark:text-purple-berry-30'
                        : 'border-gray-300 dark:border-gray-700 dark:text-gray-500 hover:border-purple-300 dark:hover:border-purple-berry'
                    ]"
                    data-gtm="pricetool-change-range"
                  >
                    {{ range === '70+' ? '70+ km' : range + ' km' }}
                  </button>
                </div>
              </div>

              <!-- Stairs Selection -->
              <div class="mt-8">
                <div class="flex items-start">
                  <label class="relative inline-flex items-start cursor-pointer">
                    <div class="flex items-center h-full">
                      <input 
                        type="checkbox" 
                        v-model="stairsEnabled"
                        class="sr-only peer"
                        data-gtm="pricetool-toggle-stairs"
                      >
                      <div class="w-11 min-w-11 h-6 bg-gray-300 peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-berry"></div>
                    </div>
                    <span class="ml-3 text-sm text-left text-gray-700 dark:text-gray-300">
                      {{ props.blok.toggleLevelDescription }}
                    </span>
                  </label>
                </div>
                <div v-if="stairsEnabled" class="flex flex-wrap gap-3 mt-3">
                  <button
                    v-for="option in stairLevelsOptions"
                    :key="option.value"
                    @click="selectedStairLevel = option.value"
                    :class="[
                      'px-2 py-2 sm:py-3 rounded-lg border-2 text-sm sm:text-base transition-colors whitespace-nowrap',
                      selectedStairLevel === option.value
                        ? 'border-purple-berry bg-purple-berry-10 dark:bg-purple-berry/20 text-purple-berry dark:text-purple-berry-30'
                        : 'border-gray-300 dark:border-gray-700 dark:text-gray-500 hover:border-purple-berry-30 dark:hover:border-purple-berry'
                    ]"
                    data-gtm="pricetool-change-stairs"
                  >
                    {{ option.label }}
                  </button>
                </div>
              </div>

              <!-- Helper Toggle hiding it to begin with
              <div class="flex items-start mt-8">
                <label class="relative inline-flex items-start cursor-pointer">
                  <div class="flex items-center h-full">
                    <input 
                      type="checkbox" 
                      v-model="hasHelper"
                      class="sr-only peer"
                      data-gtm="pricetool-toggle-help"
                    >
                    <div class="w-11 min-w-11 h-6 bg-gray-300 peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-purple-berry"></div>
                  </div>
                  <span class="ml-3 text-sm text-gray-700 dark:text-gray-300 text-left">
                    {{ props.blok.toggleHelpDescription }}
                  </span>
                </label>
              </div>
               -->
              <!-- Hiding this for now as it is not needed with current title and descriptioin
              <div class="mb-6">
                <p class="text-sm mb-6 text-gray-500 dark:text-gray-400 mt-8 text-center">
                  {{ t('priceEstimate.disclaimer') }}
                </p>
              </div>
              -->
              
              <!-- CTA Section -->
              <div v-if="props.blok.ctas && props.blok.ctas.length > 0" class="flex flex-wrap justify-center gap-4 py-6">
                <template v-for="cta in props.blok.ctas" :key="cta._uid">
                  <StoryblokComponent 
                    :blok="cta"
                  />
                </template>
              </div>

            </div>
        </div>
      </div>
    </div>
  </section>
</template>
